import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  settings,
  SettingsTypes,
  gameStats,
  CustomEvents,
  THREE,
  AudioManager,
  CameraStates,
  cameraManager,
  requestManager,
  TimesTypes,
  fpsManager,
  appWSM2021Config,
  modes,
  AppWSM2021DifficultyTypes,
  playersConfig,
  corePhasesManager,
  PlayersSortTypes,
  PlayersResultTypes,
  playersManager,
  PlayersSecondResultTypes,
  PlayerSex,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  trainingConfig,
  drawConfig,
  shootingConfig,
  aimConfig,
  windConfig,
  translatesReplacements,
  sponsorsConfig
} from './config'
import {
  MaterialsNames,
  DisciplinePhases,
  type SpecialDataFromInit,
  MinigameVersionTypes,
  DrawTypes,
  ShootingTypes,
  TexturesNames
} from './types'
import { worldEnv } from './entities/env/WorldEnv'
import { scoreDisplayPanel } from './entities/env/ScoreDisplayPanel'
import { player } from './entities/athlete/player'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssets } from '@/globals/globalvariables'
import { wind } from './entities/athlete/Wind'
import { timeLimitManager } from './TimeLimitManager'
import * as Sentry from '@sentry/vue'
import { aimingDirectionManager } from './phases/AimPhase/AimingDirectionManager'
import { opponent } from './entities/athlete/opponent/Opponent'
import {
  debugState,
  trainingState,
  tutorialState
} from '@/stores'
import { loadingState } from '@powerplay/core-minigames-ui-ssm'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     */
    this.setPlayersConfig()

    /**
     * Nastavenie specialnych sponzorov
     */
    this.setSpecialSponsor()

    // musime este nastavit, aby nebolo intro v treningu
    corePhasesManager.showIntro = !modes.isTrainingMode()

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.athlete].meshesArray || [],
        meshesAnisotropy: ['ArcheryRange_Mesh_17'],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackAction3: inputsManager.onKeyAction3,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: modes.isTutorial() ? 100 : gameConfig.numberOfAttempts,
        inputSchema: 'biathlon'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Nastavenie specialneho sponzora
   */
  private setSpecialSponsor(): void {

    const timeNow = Date.now()
    const random = THREE.MathUtils.randFloat(0, 1)
    const sponsorBannerToShow = {
      name: '',
      percent: 0,
      textureName: '',
      textureNameScreens: '',
    }

    // zatial kontrolujeme iba bannery
    sponsorsConfig.banners.forEach((sponsor) => {

      if (!sponsor.active) return

      // sponsor.name
      sponsor.times.forEach((slot) => {

        if (slot.from <= timeNow && slot.to > timeNow && random < slot.percent) {

          sponsorBannerToShow.name = sponsor.name
          sponsorBannerToShow.percent = slot.percent
          sponsorBannerToShow.textureName = sponsor.textureName
          sponsorBannerToShow.textureNameScreens = sponsor.textureNameScreens
          console.log('vyberame sponzora pre bannery: ', sponsorBannerToShow)

        }

      })

    })

    // ak je nejaky sponzor - vyberie sa posledny
    if (sponsorBannerToShow.name !== '') {

      // zmenime assety - vymazeme povodny a dame novy
      delete texturesConfig[TexturesNames.colorGradient]
      delete texturesConfig[TexturesNames.screens]
      materialsConfig[MaterialsNames.colorGradient].textureName = sponsorBannerToShow.textureName
      materialsConfig[MaterialsNames.screens].textureName = sponsorBannerToShow.textureNameScreens

    } else {

      // tu dame vsetky specialne
      delete texturesConfig[TexturesNames.colorGradientVshosting]
      delete texturesConfig[TexturesNames.screensVshosting]

    }

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Inicializacny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)
    game.setCallbackCustomUpdateQuality(this.onUpdateQuality)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 12

    // zakazeme fazu provisional results
    corePhasesManager.provisionalResultsAllowed = !modes.isTutorial()
    corePhasesManager.provisionalResultsFrequency = modes.isTutorial() ? 9999 : 3

    AudioManager.PATH_ASSETS = pathAssets

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.athleteRaceBlackMan,
      TexturesNames.athleteRaceBlackWoman,
      TexturesNames.athleteRaceMulattoMan,
      TexturesNames.athleteRaceMulattoWoman,
      TexturesNames.athleteRaceWhiteMan,
      TexturesNames.athleteRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.athleteRacePrefix}${playerInfo.race}`)

    // pridame superov, ak su
    if (opponent.uuid !== '') {

      const opponentInfo = playersManager.getPlayerById(opponent.uuid)
      usedTextures.push(`${opponentInfo?.sex}/${TexturesNames.athleteRacePrefix}${opponentInfo?.race}`)

    }

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    this.setMinigameByVersionType()
    Sentry.setContext('minigame', { id: requestManager.MINIGAME_START_ID })

    this.setUpDebug()
    // musime nastavit prefix pre meshe, aby nam nekolidovali meshe zo skyboxu s inymi
    game.prefixMeshesGroupEnabled = true
    game.shadowsManager.setShadowPlaneResolution(4, 4)
    game.shadowsManager.setShadowCameraFrustumPlaneDimensions(4, 4, 4, 4)

    if (opponent.getActive()) opponent.chooseOpponentUuid()

    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      opponent.uuid === '' ? [] : [opponent.uuid],
      this.getIgnoredTexturesNames(),
      TexturesNames.athleteRacePrefix
    )
    disciplinePhasesManager.create(this.prepareGameForNextAttempt)

    const tweenSettingsForCameraStates = modes.isTrainingMode() ?
      trainingConfig.tweenSettingsForCameraStates :
      cameraConfig.tweenSettingsForCameraStates

    this.mainCore.setTweenSettingsForStates(tweenSettingsForCameraStates)

    cameraManager.changeBaseRenderSettings(0.1, 1000)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

    // UI update
    stateManager.allowDirectionState()

  }

  /**
   * Nastavenie konfigu pre hracov
   */
  private setPlayersConfig(): void {

    playersConfig.sortType = PlayersSortTypes.descending
    playersConfig.resultType = PlayersResultTypes.points
    playersConfig.secondResultType = PlayersSecondResultTypes.number
    playersConfig.thirdResultType = PlayersSecondResultTypes.number

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()
    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    const specialData = data as SpecialDataFromInit

    // nastavime typ minihry (verzie)
    if (specialData.type !== undefined) gameConfig.minigameVersionType = specialData.type

  }

  /**
   * Nastavenie minihry podla typu verzie
   */
  private setMinigameByVersionType(): void {

    if (gameConfig.minigameVersionType === MinigameVersionTypes.b) {

      drawConfig.type = DrawTypes.bar
      aimConfig.lag.active = true
      aimConfig.shake.active = false
      aimConfig.deviation.active = true
      shootingConfig.type = ShootingTypes.press
      console.log('NASTAVUJEM TYP na B')

    } else if (gameConfig.minigameVersionType === MinigameVersionTypes.c) {

      drawConfig.type = DrawTypes.hold
      aimConfig.lag.active = true
      aimConfig.shake.active = false
      aimConfig.deviation.active = true
      shootingConfig.type = ShootingTypes.bar
      windConfig.change.frames = shootingConfig.timeLimitShot + 30
      console.log('NASTAVUJEM TYP na C')

    } else {

      // DEFAULT

      drawConfig.type = DrawTypes.hold
      aimConfig.lag.active = true
      aimConfig.shake.active = true
      aimConfig.deviation.active = false
      shootingConfig.type = ShootingTypes.hold
      console.log('NASTAVUJEM TYP na A')

    }

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    // pridame hraca do anistropy meshov,aby bol kvalitnejsi
    const sexPrefix = playersManager.getPlayer().sex === PlayerSex.female ? 'f_' : ''
    game.dataForCore.meshesAnisotropy?.push(`${sexPrefix}body_low`)

    worldEnv.create()
    player.create()
    if (opponent.uuid !== '') opponent.create()
    player.createTools()
    if (opponent.uuid !== '') opponent.createTools()

    this.setUpDebug()

  }

  /**
   * Ak sa zmeni kvalita, vykonaju sa nasledujuce veci, pre optimalizaciu performance
   * @param settingValue - Hodnota kvality
   */
  public onUpdateQuality = (settingValue: number): void => {

    // hybanie vlajok
    worldEnv.movingFlagsEnabled = settingValue >= 7

    // hybanie skyboxu
    worldEnv.movingSkyboxEnabled = settingValue >= 5

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      worldEnv,
      inputsManager,
      player,
      opponent,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      setHUDVisibility: () => ( debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE,
      scoreDisplayPanel
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()

    playersManager.setCompareSkillBeforeTimestamp()


  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    if (timeLimitManager.check()) return

    wind.update()
    disciplinePhasesManager.update()
    this.checkFpsRequest()

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      trainingState().firstTutorialMessage = true
      game.pauseGame()
      return

    }
    player.updateAfterPhysics()
    if (opponent.uuid !== '') opponent.updateAfterPhysics()

    const activeLockOnArrow = disciplinePhasesManager.phaseShooting.activeLockOnArrow
    const activeLookAtArrow = disciplinePhasesManager.phaseShooting.activeLookAtArrow

    this.actualCameraPosition.copy(activeLockOnArrow ?
      player.arrow.meshFlight.position :
      player.getPosition())
    if (
      disciplinePhasesManager.actualPhase > DisciplinePhases.start &&
      disciplinePhasesManager.actualPhase < DisciplinePhases.finish
    ) {

      this.actualCameraQuaternion.slerp(
        activeLockOnArrow ? player.arrow.meshFlight.quaternion : player.getQuaternion(),
        activeLockOnArrow ? gameConfig.cameraQuaternionLerp : 1
      )

    } else {

      this.actualCameraQuaternion.copy(player.getQuaternion())

    }

    const isAimPhase = disciplinePhasesManager.oneOfPhaseIsActual([DisciplinePhases.aim])
    const isShootingPhase = disciplinePhasesManager.oneOfPhaseIsActual([DisciplinePhases.shooting])

    if (
      isAimPhase ||
      (isShootingPhase && !activeLockOnArrow && disciplinePhasesManager.phaseShooting.activeMovingOnTrack)
    ) {

      this.actualCameraPosition.copy(aimingDirectionManager.aimingPoint.position)

    }

    if (activeLookAtArrow) {

      this.actualCameraPosition.copy(player.arrow.meshFlight.position.clone())

    }
    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      [/* hill.hillMesh */],
      cameraConfig.distanceFromGround,
      cameraManager.isThisCameraState(CameraStates.disciplineOutro) || activeLookAtArrow,
      activeLookAtArrow || disciplinePhasesManager.phaseShooting.isInEmotion ? undefined : {
        lerpCoef: 1, // instalerp je stale, nemame nikde potrebu davat lazy kameru
        newQuat: activeLockOnArrow ?
          player.arrow.meshFlight.quaternion.clone() :
          player.getQuaternion().clone()
      },
      undefined,
      undefined,
      activeLookAtArrow ? 0 : undefined
    )

    stateManager.setUpdateTimeState()

    if (modes.isTutorial()) tutorialFlow.update()

    worldEnv.manageSkyboxClouds()
    worldEnv.updateFlags()

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    player.updateAnimations(delta)
    if (opponent.uuid !== '') opponent.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

  /**
   * Pripravenie hry pre dalsi pokus
   */
  private prepareGameForNextAttempt = (): void => {

    console.log('prepaaaare next attempt')
    disciplinePhasesManager.resetAttempt()

  }

}
